import { defineStore } from "pinia";
import type { UseFetchOptions } from "@vueuse/core";
import lodash from "lodash";
import { useCookie, useFetch, useRuntimeConfig } from "#app";
import useRefreshToken from "~/composables/useRefreshToken";

const { BASE_URL } = useRuntimeConfig().public;
const auth = useCookie<AuthResponse>("auth");
const { refreshToken } = useRefreshToken();

export const activationStoreInitialState: ActivationState = {
  campaignData: {
    id: "",
    name: "",
    brand_id: "",
    brand_name: "",
    campaign_display_name: "",
    has_metrics: false,
    platforms: [],
  },
  platformsStatus: [],
  loading: false,
  deleted: false,
  cancelled: false,
};

export const useActivationStore = defineStore("activationStore", {
  state: (): ActivationState => activationStoreInitialState,
  actions: {
    async getCampaignData(campaignId: string) {
      if (this.campaignData.id !== "") {
        this.resetState();
      }
      this.loading = true;
      const requestConfig = {
        headers: {
          Authorization: `Bearer ${auth.value.access}`,
        },
        method: "GET",
      };
      const { data, error } = await useFetch<CampaignResponseType>(
        `${BASE_URL}/api/v2/campaigns/${campaignId}/`,
        requestConfig as UseFetchOptions,
      );
      if (error.value?.statusCode === 401) {
        await refreshToken();
      } else if (error.value?.statusCode === 404) {
        navigateTo("/campaigns/");
      } else if (data.value) {
        this.campaignData = data.value;
        this.platformsStatus = this.campaignData.platforms.map((platform) => ({
          id: platform.id,
          platform_activation_status: platform.platform_activation_status,
        }));
      }
      this.loading = false;
    },
    async activatePlatform(platformId: number) {
      this.loading = true;
      const requestConfig = {
        headers: {
          Authorization: `Bearer ${auth.value.access}`,
        },
        body: {
          platform_activation_ids: [platformId],
        },
        method: "POST",
      };
      const { data } = await useFetch<ActivationResponse>(
        `${BASE_URL}/api/v2/campaigns/${this.campaignData.id}/platform-activation/`,
        requestConfig as UseFetchOptions,
      );
      this.setPlatformStatus(data.value);
      this.loading = false;
    },
    async activateAllPlatforms() {
      this.loading = true;
      const requestConfig = {
        headers: {
          Authorization: `Bearer ${auth.value.access}`,
        },
        body: {
          platform_activation_ids: this.platformsStatus.map((platform) => platform.id),
        },
        method: "POST",
      };
      const { data } = await useFetch<ActivationResponse>(
        `${BASE_URL}/api/v2/campaigns/${this.campaignData.id}/platform-activation/`,
        requestConfig as UseFetchOptions,
      );
      this.setPlatformStatus(data.value);
      this.loading = false;
    },
    async pausePlatform(platformId: number) {
      this.loading = true;
      const requestConfig = {
        headers: {
          Authorization: `Bearer ${auth.value.access}`,
        },
        body: {
          platform_activation_ids: [platformId],
        },
        method: "POST",
      };
      const { data } = await useFetch<ActivationResponse>(
        `${BASE_URL}/api/v2/campaigns/${this.campaignData.id}/pause-platform-activation/`,
        requestConfig as UseFetchOptions,
      );
      this.setPlatformStatus(data.value);
      this.loading = false;
    },
    async pauseAllPlatforms() {
      this.loading = true;
      const requestConfig = {
        headers: {
          Authorization: `Bearer ${auth.value.access}`,
        },
        body: {
          platform_activation_ids: this.platformsStatus.map((platform) => platform.id),
        },
        method: "POST",
      };
      const { data } = await useFetch<ActivationResponse>(
        `${BASE_URL}/api/v2/campaigns/${this.campaignData.id}/pause-platform-activation/`,
        requestConfig as UseFetchOptions,
      );
      this.setPlatformStatus(data.value);
      this.loading = false;
    },
    async deleteCampaign(campaignId: string) {
      this.loading = true;
      const requestConfig = {
        headers: {
          Authorization: `Bearer ${auth.value.access}`,
        },
        method: "DELETE",
      };
      await useFetch<ActivationResponse>(
        `${BASE_URL}/api/v2/campaigns/${campaignId}/`,
        requestConfig as UseFetchOptions,
      );
      this.$reset();
      this.deleted = true;
    },
    async cancelCampaign(campaignId: string) {
      this.loading = true;
      const requestConfig = {
        headers: {
          Authorization: `Bearer ${auth.value.access}`,
        },
        method: "POST",
      };
      await useFetch<ActivationResponse>(
        `${BASE_URL}/api/v2/campaigns/${campaignId}/cancel-campaign/`,
        requestConfig as UseFetchOptions,
      );
      this.$reset();
      this.cancelled = true;
    },
    setPlatformStatus(data: ActivationResponse | null) {
      if (data && Array.isArray(data.results)) {
        data.results.forEach((activationResult) => {
          const { requested_activation_id, platform_activation_status } = activationResult;
          const platformIndex = this.platformsStatus.findIndex((platform) => platform.id === requested_activation_id);
          if (platformIndex !== -1) {
            this.platformsStatus[platformIndex].platform_activation_status = platform_activation_status;
          } else {
            this.platformsStatus = [
              ...this.platformsStatus,
              {
                id: requested_activation_id,
                platform_activation_status,
              },
            ];
          }
        });
      }
    },
    resetState() {
      this.campaignData = lodash.cloneDeep(activationStoreInitialState.campaignData);
      this.platformsStatus = [];
      this.loading = false;
      this.deleted = false;
      this.cancelled = false;
    },
  },
});
